// C:\Users\james\Portfolio\src\data\projectsData.js
export const dummyProjects = [
  {
    id: "apt-finder",
    title: "Apt Finder",
    category: "Mobile Development",
    summary: "An AR-powered solution that helps delivery drivers easily locate apartment numbers using their phone camera.",
    image: "/assets/images/apt-finder-cover.jpg",
    tags: ["AR", "Mobile", "React"],
    featured: true,
    fullDescription: `Back when I used to deliver for DoorDash during the pandemic, I dreaded apartment orders. It felt like navigating a torturous maze just to deliver a $5 order to a third-floor apartment—and, of course, the apartment number was never the one I needed when I got there.
That frustration sparked an idea: What if I could simply point my phone’s camera at a building—any building—and immediately see the apartment number I needed hovering in 3D space, as though I were looking through walls. Think of it like a Pokémon GO, but instead of Pokémon, you’d see addresses.
The concept—Apt Finder—would use AR technology to overlay addresses in 3D space, making it simple to find your way in complex locations. While I'm excited about the possibilities, I also know it's an ambitious project that will take significant time and resources to bring to life.

For now, Apt Finder is on the back burner, but it's a dream I'm determined to pursue when the time is right. Until then, I'll keep brainstorming and refining the concept. Who knows? Maybe one day it'll be a game-changer for delivery drivers everywhere.`,
    media: {
      prototype: [
        "/assets/images/apt-finder-proto-1.jpg",
        "/assets/images/apt-finder-proto-2.jpg",
        "/assets/images/apt-finder-proto-3.jpg"
      ],
      video: "/assets/videos/apt-finder-demo.mp4",
      figmaEmbed: "https://embed.figma.com/proto/GG1DAjjK21ZXL4qvAiKhng/Aptfinder-design-V2?node-id=1-4&starting-point-node-id=1%3A4&embed-host=share"
    },
    status: "In Development"
  },
  {
    id: "nuti-pocket",
    title: "Nuti-Pocket",
    category: "Mobile Development",
    summary: "A smart nutrition tracking app that provides real-time nutritional information for your meals using weight measurements.",
    image: "/assets/images/nuti-poket-cover.jpg",
    tags: ["React", "Physical Product", "Health Tech", "Mobile App"],
    featured: true,
    fullDescription: `When I was a kid, I spent countless hours playing games like Fallout and Skyrim. One of my favorite things was how every item you picked up had its own stats. A loaf of bread restored 20 HP, and a honey bun gave you a +20 stamina boost. In games, it was simple: everything you consumed had clear, immediate effects.

In real life, though, it’s not so straightforward. We know the food we eat impacts our health and energy, but figuring out exactly how? That’s a whole other challenge. Nutritional labels are either too complex or too easy to overlook, leaving us guessing—or hoping—we’re making the right choices.

That’s what inspired me to create Nuti-Pocket, a tool designed to bring that same “visible stats” experience from games into our everyday lives. Here’s the idea: weigh your meal, sync the data with Nuti-Pocket, and—boom—you get a full nutritional breakdown on your phone. No more squinting at labels or deciphering ingredient lists. Just a clean, game-like stats display for your food.

Since first dreaming this up back in 2016, some companies have tried similar ideas. But I believe there’s still room to do it better. My goal is for Nuti-Pocket to feel intuitive and engaging. If we can make nutritional info easy to understand—and even fun—we can empower people of all generations to make healthier, more informed decisions about what they put into their bodies.

I’m currently in the prototyping phase, refining features and testing the concept. There’s still much work to be done, and I’m just one person—but this is a project I’m truly excited to bring to life.`,
    media: {
      prototype: [
        "/assets/images/nuti-poket-proto-1.jpg",
        "/assets/images/nuti-poket-proto-2.jpg",
        "/assets/images/nuti-poket-proto-3.jpg",
        "/assets/images/nuti-poket-proto-4.jpg"
      ],
      video: "/assets/videos/nuti-poket-demo.mp4",
      figmaEmbed: "https://embed.figma.com/proto/JaTTJJmkKe0OwobnB3Fw10/Untitled?node-id=172-18&starting-point-node-id=251%3A247&embed-host=share"
    },
    status: "Prototype Phase"
  },
  {
    id: "RideShare App",
    title: "RideShare App",
    category: "Mobile Development",
    summary: "Cross-platform mobile app delivering a seamless experience for drivers and passengers.",
    image: "/assets/images/project-placeholder-2.jpg",
    tags: ["React", "Heroku", "Real-Time Data"],
    featured: true,
    fullDescription: `I’m currently developing a custom RideShare App for a friend who works as an Uber driver. After hearing his day-to-day challenges—unclear routes, unpredictable passenger requests, and a general lack of personalized tools—I decided to build something tailor-made for drivers. 

The app’s main focus is on driver-centric features. From the moment a passenger submits a request, the driver sees all the crucial details: pickup point, destination, estimated fare, distance, and travel time. I’ve integrated mapping tools for efficient route planning and real-time fare calculations to make each ride more predictable and profitable from the driver’s perspective.

On the passenger side, the interface remains straightforward: enter your pickup and drop-off points, get an estimated fare, and send the request. Meanwhile, drivers enjoy a streamlined dashboard that offers quick decision-making tools—accept or decline rides with a single tap, view driving history, and toggle their availability.

By leveraging React and a cloud-based backend, I’m ensuring scalability and flexibility for future iterations. Down the line, I’d love to introduce advanced metrics, integrate EV charging station info, and potentially expand to a broader user base. For now, I’m working closely with my friend to refine features and ensure that this app genuinely improves his daily workflow behind the wheel.

This project is still a work in progress, but it marks an exciting step in transforming the rideshare experience—putting the driver’s needs at the heart of the platform.`,
    media: {
      prototype: [
        "/assets/images/project-beta-1.jpg",
        "/assets/images/project-beta-2.jpg",
        "/assets/images/project-beta-3.jpg",
        "/assets/images/project-beta-4.jpg"
      ]
    },
    status: "In Progress"
  }
];
