// C:\Users\james\Portfolio\src\common\components\Footer\Footer.jsx
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner, OverlayTrigger, Tooltip, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useHttpClient from '../../hooks/http-hook';
import './Footer.scss';

const Footer = () => {
  const [visits, setVisits] = useState(6);
  const { isLoading, error, sendRequest } = useHttpClient();

  // Fallback to a default key if REACT_APP_COUNTAPI_KEY is not set
  const countApiKey = process.env.REACT_APP_COUNTAPI_KEY || 'defaultKey';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `https://api.countapi.xyz/hit/itsrakesh.com/${countApiKey}`
        );
        setVisits(responseData.value);
      } catch (err) {
        // Handle error silently
      }
    };
    fetchData();
  }, [sendRequest, countApiKey]);

  return (
    <div id="footer">
      <footer>
        <Row>
          <Col sm>
            {!isLoading && visits && (
              <h6>
                Visits:{' '}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Times Viewed</Tooltip>}
                >
                  <span className="visits">
                    {isLoading ? (
                      <Spinner animation="grow" size="sm" />
                    ) : (
                      visits
                    )}
                    {error && <span>{error}</span>}
                  </span>
                </OverlayTrigger>
              </h6>
            )}
          </Col>
        </Row>
        <span className="divider"></span>
        <Row className="extras">
          <Col sm>
            <Stack>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Stack>
          </Col>
        </Row>
      </footer>
      <Stack className="copyright" direction="horizontal" gap={5}>
        <p>© 2021 - 2024 [BR6] All rights reserved.</p>
        <p className="ms-auto">v1.6.0</p>
      </Stack>
    </div>
  );
};

export default Footer;
